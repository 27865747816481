import React, { useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"

import { generateFormField } from "elements/Form/services/form"
import { healthSurveySchema } from "../utils/healthSurveySchema"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"
import { isFutureDate } from "../../../../services/date"

import { heightAndWeightOptions } from "../../../Elements/utils/heightAndWeightOptions"

let { parseFormField } = require("../../../../services/airtable")

const HealthSurvey = ({ pageContext }) => {
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )
  let formFields = pageContext.formFields
  let validationSchema = healthSurveySchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSubmitCashlessHealthSurvey = (values, { setErrors }) => {
    let lastVisitedDoctor = values?.lastVisitedDoctor
    lastVisitedDoctor = `${lastVisitedDoctor?.month?.value} ${lastVisitedDoctor?.date?.value} ${lastVisitedDoctor?.year}`

    if (!!isFutureDate({ date: lastVisitedDoctor })) {
      setErrors({
        lastVisitedDoctor: {
          month: { value: "Please enter a valid date." },
          date: { value: "Please enter a valid date." },
        },
      })
      return values
    }
    flexmedCashlessDispatch({
      type: "SAVE_HEALTH_SURVEY",
      payload: {
        ...values,
      },
    })
    flexmedCashlessDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...flexmedCashlessState,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  useEffect(() => {
    flexmedCashlessDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      currentStep={3}
      totalSteps={4}
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...flexmedCashlessState }}
          onSubmit={handleSubmitCashlessHealthSurvey}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="content"
                  isRequired={section?.isSectionRequired}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  <ol>
                    {section?.fields.map((field, index) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <li key={index}>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: {
                                ...field,
                                ...(field?.name === "height" && {
                                  options: heightAndWeightOptions.height,
                                }),
                                ...(field?.name === "weight" && {
                                  options: heightAndWeightOptions.weight,
                                }),
                              },
                              values,
                              setFieldValue,
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </li>
                        )
                      }
                      return null
                    })}
                  </ol>
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ButtonGroup
                back={{
                  link: pageContext.backPath,
                }}
                next={{
                  label: `Next: ${pageContext.module.next}`,
                  link: pageContext.nextPath,
                  type: "submit",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default HealthSurvey
